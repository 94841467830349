// 质押融资
<template>
  <div class="financial-pledgeFinancing back-border" v-html="info.text"> </div>
</template>

<script>
import { mapGetters } from "vuex"
import { getInfo } from '@/api'
export default {
  data () {
    return {
      info: {}
    };
  },
  computed: {
    ...mapGetters(['getNavItem'])
  },
  mounted () {
    this.getData()
  },
  methods: {
    async getData () {
      let { data } = await getInfo(this.getNavItem.id)
      this.info = data[0]
    }
  },
};
</script>

<style scoped lang="less">
::v-deep.financial-pledgeFinancing {
  padding: 30px;
  img {
    width: 100%;
  }
}
</style>